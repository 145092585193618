@import url(./datepicker.css);
@import url(./toggle.css);

@tailwind base;
@tailwind components;
@tailwind utilities;

h1,
h2,
h3,
h4,
h5,
h6 {
  @apply text-dats-secondary-5;
}

body {
  @apply text-p1 text-dats-secondary-5;
}

h1 {
  @apply text-h2 lg:text-h1;
}
h2 {
  @apply text-h2;
}

h3 {
  @apply text-lg;
  @apply font-medium;
}

h4 {
  @apply text-base;
  @apply font-medium;
}

h5 {
  @apply text-sm;
  @apply font-medium;
}

h6 {
  @apply text-sm;
  @apply font-bold;
}

a {
  @apply text-inherit;
  @apply text-dats-secondary-3;
  @apply underline;
  @apply font-semibold;
}

#not-found-richtext a {
  @apply text-dats-secondary-3;
  @apply underline;
  @apply text-lg;
  @apply font-normal;
}

/* Start React slider */
.horizontal-slider {
  width: 100%;
  max-width: 100%;
  height: 24px;
  margin: auto;
}

.track {
  position: relative;
  @apply bg-dats-primary-1;
}

.track.track-0 {
  background: #d2d2ca;
}

.horizontal-slider .track {
  top: 10px;
  height: 4px;
}

.horizontal-slider .track-0 {
  top: 10px;
  height: 4px;
}

.horizontal-slider .mark {
  top: 6px;
  width: 12px;
  height: 12px;
  background: #d2d2ca;
  border-radius: 100%;
}

.mark.mark-uncompleted {
  @apply bg-dats-primary-1;
}

.horizontal-slider .thumb {
  cursor: pointer;
  top: 0px;
  width: 24px;
  height: 24px;
  border-radius: 100%;
  background: #fff;
  border-width: 3px;
  @apply border-dats-primary-1;
}
/* End React slider */

input[id^='react-select-'] {
  opacity: 1 !important;
}

::-webkit-search-cancel-button {
  appearance: none;
}

ol.styled-ol {
  counter-reset: item;
}

li.styled-ol-item {
  @apply relative;
}

li.styled-ol-item > div {
  @apply pl-6;
}

li.styled-ol-item--color > div {
  @apply pl-8;
}

li.styled-ol-item::before {
  content: counter(item); /* Use the value of the counter as content */
  counter-increment: item; /* Increment the counter */
  @apply absolute left-0 top-0 flex items-center justify-center font-bold;
}

li.styled-ol-item--color:before {
  @apply h-6 w-6 rounded-full bg-dats-secondary-1 text-white;
}

.ais-InfiniteHits-loadMore::before {
  content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='21' height='21' viewBox='0 0 21 21' fill='none'%3E%3Cpath d='M10.4998 3.62109C10.9601 3.62109 11.3332 3.99419 11.3332 4.45443V14.1093L14.9106 10.5318C15.236 10.2064 15.7637 10.2064 16.0891 10.5318C16.4145 10.8573 16.4145 11.3849 16.0891 11.7103L11.0891 16.7103C10.7637 17.0358 10.236 17.0358 9.91059 16.7103L4.91058 11.7103C4.58515 11.3849 4.58515 10.8573 4.91058 10.5318C5.23602 10.2064 5.76365 10.2064 6.0891 10.5318L9.6665 14.1093V4.45443C9.6665 3.99419 10.0396 3.62109 10.4998 3.62109Z' fill='%23005E75'/%3E%3C/svg%3E");
  position: relative;
  display: inline-block;
  transform: translateY(5px);
  margin-right: 5px;
}

.grecaptcha-badge {
  opacity: 0;
}

/* Custom map spinner */
.pos-center {
  position: fixed;
  top: calc(50% - 24px);
  left: calc(50% - 24px);
}

.mapSpinner {
  border: 8px solid #00b5e2;
  border-top: 8px solid #00b5e200;
  border-radius: 50%;
  width: 48px;
  height: 48px;
  animation: spin 750ms linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.custom-break-word {
  word-break: break-word;
}
