.inc .main-content-block {
  max-width: 1066px;
  margin-left: auto;
  margin-right: auto;
}

@media only screen and (max-width: 1066px) {
  .inc .main-content-block {
    padding-right: 15px;
    padding-left: 15px;
  }
}

#app.inc .inc-breadcrumb {
  background: #f6f5f5; /* dats s10 light gray */
}
.inc-breadcrumb__items {
  width: 100%;
  max-width: 1066px;
  margin-left: auto;
  margin-right: auto;
  list-style: none;
  display: flex;
  padding: 8px;
}
.inc-breadcrumb__item-label {
  position: relative;
  margin: 8px 0px;
  height: 20px;
  font-family: var(--font-opensans), regular;
  font-size: 14px;
  color: #005e75; /* dats-green */
}

.inc-chip__label {
  color: #005e75; /* dats-green */
}
.inc-chip {
  border: 1px solid #e8e8e8;
  border-radius: 5px;
}
.inc-chip:hover,
.router-link-active.inc-chip {
  background: #005e75;
  cursor: pointer;
}

.inc-contact-block__content {
  font-family: var(--font-opensans), regular;
  background: #f6f5f5; /* dats s10 light gray */
}
.inc-contact-block__content-title {
  color: #3b4344;
  font-family: var(--font-), regular;
  font-size: 18px;
  text-align: center;
  font-weight: 500;
  line-height: 22px;
  padding-bottom: 8px;
}

.inc-contact-block__content-text {
  font-family: var(--font-opensans), regular;
  font-size: 16px;
  line-height: 24px;
}
.inc-contact-block__phone-number {
  color: #005e75;
}

.inc-contact-block--horizontal .inc-contact-block__phone {
  margin-top: 16px;
}
/* .inc-contact-block__image img {
  display: none;
} */

.inc-cta-button--primary {
  color: #fff;
  background: #005e75;
}

#app.inc.inc .inc-search-header {
  background: #f6f5f5; /* dats s10 light gray */
}
#app.inc .inc-search-header .row .inc-search-header__title h1 {
  font-family: var(--font-satoshi), regular;
  font-weight: bold;
  font-size: 32px;
  line-height: 38px;
  color: #3b4344;
}
@media only screen and (max-width: 768px) {
  #app.inc
    .inc-search-header.search-block--landing
    .row
    .inc-search-header__title
    h1 {
    font-family: var(--font-satoshi), regular;
    font-weight: bold;
    font-size: 32px;
    line-height: 38px;
    color: #3b4344;
  }
}

.inc-trending-articles__title {
  color: #444;
  font-family: var(--font-satoshi), regular;
  font-size: 24px;
  font-weight: 700;
  line-height: 28px;
}

.inc-trending-articles__list {
  padding: 0;
  margin-top: 32px;
  border-top: 1px solid #e8e8e8;
  color: #3b4344;
  list-style: none;
  font-family: var(--font-opensans), regular;
  font-size: 16px;
  font-style: normal;
  line-height: 24px;
}
.inc-trending-articles__list .inc-trending-article a {
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: space-between;
  list-style-type: none;
  padding: 15px;
  padding-left: 0;
  border-bottom: 1px solid #e8e8e8;
  color: #3b4344;
  font-family: var(--font-opensans), regular;
  font-size: 16px;
  font-weight: 400;
}
.inc-trending-articles__list .inc-trending-article:hover a {
  color: #005e75;
}
.inc-trending-articles__list .inc-trending-article__arrow {
  color: #005e75;
}

@media only screen and (max-width: 768px) {
  #app.inc.inc .faq-left-nav-column {
    padding-right: 0;
    width: 100%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1200px) {
  .inc-contact__content {
    display: flex;
    flex-direction: column;
  }
  .inc-contact__content-aside {
    width: 100%;
  }
  .inc-contact__content-main {
    width: 100%;
  }
}

/* @media only screen and (max-width: 1066) {
  #app.inc.inc .faq-left-nav-column {
    padding-right: 0;
    width: 100%;
    padding: 0px 16px;
    background: red;
  }
} */

.inc-article-list {
  font-family: var(--font-opensans), regular;
  font-weight: 400;
  font-size: 16px;
  background: none;
}
.inc-article-list ul {
  border-top: 1px solid #e8e8e8;
}
.inc-article-list ul .inc-article-list__item a {
  font-weight: 400;
  border-bottom: 1px solid #e8e8e8;
}

.inc-article-list ul .inc-article-list__item a.router-link-active,
.inc-article-list ul .inc-article-list__item a:hover {
  font-weight: 600;
  color: #005e75;
}

.inc-article-detail {
  font-family: var(--font-opensans), regular;
}

.inc .inc-search-input {
  width: 80%;
  border: 1px solid #e8e8e8;
  border-radius: 5px;
}
.inc .inc-search-input:focus {
  border: 1px solid #005e75;
}
.inc .inc-search-bar input {
  padding-left: 20px;
  border-bottom-left-radius: 5px;
  border-top-left-radius: 5px;
  border: none;
  font-size: 15px;
  width: 100%;
  text-align: left;
  z-index: 1;
  transition: border-radius 0.3s ease-in-out;
}
.inc .submitButton {
  transition: border-radius 0.3s ease-in-out;
  width: 20px;
  height: 52px;
  margin: 0;
  padding: 0;
  padding-right: 20px;
  padding-left: 20px;
  border: none;
  vertical-align: top;
  border-bottom-right-radius: 5px;
  border-top-right-radius: 5px;
  color: #005e75;
  background: white;
  box-sizing: initial !important;
  -webkit-box-sizing: content-box !important;
}
.inc .submitButton:hover {
  background: #005e75;
  color: #fff;
}
.inc .inc-suggested-search {
  z-index: 1;
  position: absolute;
  left: 0;
  list-style: none;
  margin: 0;
  padding: 0;
  top: 100%;
  background-color: #fff;
  width: 100%;
  border-top: 1px solid #ccc;
  box-shadow: 1px 1px 17px 0 #ccc;
  border-bottom-left-radius: 22px;
  border-bottom-right-radius: 22px;
  text-align: left;
}

#app.inc .inc-contact__select {
  color: #3b4344;
  border: 1px solid #e8e8e8;
  padding: 16px 30px 16px 30px;
}

#app.inc .inc-contact__select-title {
  font-family: var(--font-opensans), regular;
  margin-bottom: 8px;
}

#app.inc .inc-contact__select-description {
  font-family: var(--font-opensans), regular;
  font-size: 15px;
  margin-bottom: 8px;
}

#app.inc .inc-contact__ccx-form {
  margin-bottom: 100px;
  font-family: var(--font-opensans), regular;
}

.inc-faq-block {
  font-family: var(--font-opensans), regular;
  background: #f6f5f5; /* dats s10 light gray */
  padding: 40px 20px;
  text-align: center;
}
.inc-faq-block p {
  font-family: var(--font-opensans), regular;
  line-height: 1.5;
}

#app.inc.inc .faq-left-nav-column h3 {
  font-family: var(--font-opensans), regular;
}

.search-page {
  margin-top: 32px;
}
